import PropTypes from "prop-types";
import labels from "../../../config/labels";
import { replaceInHtmlText } from "../../../utils/helper";
import { useStyles } from "./Disclaimer.style";
import {
  CANDIDATE_STATEMENT,
  FRONT_END,
  HERE_REGEX,
  LETTERS_TO_EDITOR,
  POLICE_BLOTTER,
  PRESS_RELEASE,
  TAPINTO_NET,
  CONTACT_EMAIL,
  TAPINTO_MARKETING,
  LINKS_TARGET,
} from "./disclaimerConstants";

export const DEFAULT_REPLACE_TEXT = [
  {
    toReplace: TAPINTO_NET,
    toReplaceWith: labels.HTML_LINK_TAPINTO_NET,
  },
  {
    toReplace: CONTACT_EMAIL,
    toReplaceWith: labels.HTML_LINK_CONTACT_INFO,
  },
  {
    toReplace: TAPINTO_MARKETING,
    toReplaceWith: labels.HTML_LINK_TAPINTO_MARKETING_NET,
  },
];

const Disclaimer = ({
  categoryName,
  column,
  isAdvertisement,
  articleColumnCategory,
  submittedVia,
  disclaimerPolicyPageLinks,
  isDiyForm,
  customDisclaimer,
}) => {
  const classes = useStyles();

  const policyPageHtmlLink = (linksArray, type) => {
    const pagePath = linksArray.find((item) => item.page.includes(type))?.path;
    let linkContent = "";

    switch (type) {
      case LETTERS_TO_EDITOR:
        linkContent = labels.LETTER_TO_THE_EDITOR_POLICY;
        break;
      case CANDIDATE_STATEMENT:
        if (isDiyForm) {
          linkContent = labels.CANDIDATE_STATEMENT_POLICY_LINK_DIY;
        } else {
          linkContent = labels.CANDIDATE_STATEMENT_POLICY;
        }
        break;
      default:
        break;
    }

    return pagePath && linkContent
      ? `<a target=${LINKS_TARGET} href='${pagePath}'>${linkContent}</a>`
      : "";
  };

  if (
    categoryName &&
    !column &&
    !isAdvertisement &&
    articleColumnCategory !== CANDIDATE_STATEMENT
  ) {
    switch (articleColumnCategory) {
      case LETTERS_TO_EDITOR:
        if (customDisclaimer) {
          return replaceInHtmlText(customDisclaimer, [], classes.disclaimer);
        }
        const lettersToEditorDisclaimer = isDiyForm
          ? labels.DISCLAIMER_LETTERS_TO_THE_EDITOR
          : labels.DISCLAIMER_LETTERS_TO_THE_EDITOR +
            labels.HTML_DISCLAIMER_LETTERS_TO_THE_EDITOR_SUBMIT_DIY;
        return replaceInHtmlText(
          lettersToEditorDisclaimer,
          DEFAULT_REPLACE_TEXT.concat([
            {
              toReplace: labels.SUBMIT_LETTER_TO_EDITOR_HERE,
              toReplaceWith: labels.HTML_LINK_WRITE_LETTERS_TO_EDITOR,
            },
            {
              toReplace: labels.LETTER_TO_THE_EDITOR_POLICY,
              toReplaceWith: policyPageHtmlLink(
                disclaimerPolicyPageLinks,
                LETTERS_TO_EDITOR
              ),
            },
          ]),
          classes.disclaimer
        );
      case POLICE_BLOTTER:
        return replaceInHtmlText(
          labels.DISCLAIMER_POLICE_BLOTTER,
          DEFAULT_REPLACE_TEXT.concat([
            {
              toReplace: HERE_REGEX,
              toReplaceWith: labels.HTML_LINK_POLICE_BLOTTER_REMOVAL,
            },
          ]),
          classes.disclaimer
        );
      case PRESS_RELEASE:
        if (submittedVia === FRONT_END) {
          if (customDisclaimer) {
            return replaceInHtmlText(customDisclaimer, [], classes.disclaimer);
          }
          return replaceInHtmlText(
            labels.DISCLAIMER_ADVERTISEMENT_OR_FRONTEND_PRESS_RELEASE,
            DEFAULT_REPLACE_TEXT,
            classes.disclaimer
          );
        }
      default:
        return "";
    }
  } else if (column && column.hide_disclaimer == false) {
    if (customDisclaimer) {
      return replaceInHtmlText(customDisclaimer, [], classes.disclaimer);
    }
    return replaceInHtmlText(
      labels.DISCLAIMER_COLUMN,
      DEFAULT_REPLACE_TEXT.concat([
        {
          toReplace: HERE_REGEX,
          toReplaceWith: labels.HTML_LINK_WRITE_TO_COLUMN,
        },
      ]),
      classes.disclaimer
    );
  } else if (categoryName && articleColumnCategory === CANDIDATE_STATEMENT) {
    if (customDisclaimer) {
      return replaceInHtmlText(customDisclaimer, [], classes.disclaimer);
    }
    return replaceInHtmlText(
      isDiyForm
        ? labels.CANDIDATE_STATEMENT_DIY_POLICY
        : labels.DISCLAIMER_CANDIDATE_STATEMENTS,
      DEFAULT_REPLACE_TEXT.concat([
        {
          toReplace: isDiyForm
            ? labels.CANDIDATE_STATEMENT_POLICY_LINK_DIY
            : labels.CANDIDATE_STATEMENT_POLICY,
          toReplaceWith: policyPageHtmlLink(
            disclaimerPolicyPageLinks,
            CANDIDATE_STATEMENT
          ),
        },
      ]),
      classes.disclaimer
    );
  } else if (isAdvertisement) {
    if (articleColumnCategory === PRESS_RELEASE) {
      if (customDisclaimer) {
        return replaceInHtmlText(customDisclaimer, [], classes.disclaimer);
      }
      return replaceInHtmlText(
        labels.DISCLAIMER_ADVERTISEMENT_OR_FRONTEND_PRESS_RELEASE,
        DEFAULT_REPLACE_TEXT,
        classes.disclaimer
      );
    } else {
      return replaceInHtmlText(
        labels.DISCLAIMER_ADVERTISEMENT,
        DEFAULT_REPLACE_TEXT,
        classes.disclaimer
      );
    }
  } else {
    return "";
  }
};

Disclaimer.defaultProps = {
  isDiyForm: false,
};

Disclaimer.propTypes = {
  categoryName: PropTypes.string,
  column: PropTypes.string,
  isAdvertisement: PropTypes.bool,
  articleColumnCategory: PropTypes.string,
  submittedVia: PropTypes.string,
  disclaimerPolicyPageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      page: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  isDiyForm: PropTypes.bool,
  customDisclaimer: PropTypes.string,
};

export default Disclaimer;
