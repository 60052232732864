import React, { useEffect } from "react";
import clsx from "clsx";
import { object } from "prop-types";
import { buildAdTargets } from "../../utils/googleTagManagerHelper";
import Typography from "../../common/Typography/Typography";
import Masterhead from "../../components/Masterhead/Masterhead";
import Footer from "../../components/Footer/Footer";
import RightBlocks from "../../components/RightBlocks/RightBlocks";
import { useTown, withTownContextProvider } from "../../context/TownContext";
import { useStyles } from "./Pages.style";

const Pages = ({ page, masthead_props, footer_props, town }) => {
  const classes = useStyles();
  const { setTown } = useTown();

  useEffect(() => {
    if (town) {
      setTown(town);
    }
  }, [town]);

  return (
    <>
      <Masterhead {...masthead_props} />

      <div
        className={clsx(classes.container, !town && classes.masthead_padding)}
      >
        <div className={classes.page}>
          <Typography level="h2">{page?.name}</Typography>
          <div
            className={classes.body}
            dangerouslySetInnerHTML={{ __html: page?.body }}
          />
        </div>

        {town && (
          <div className={classes.right_blocks}>
            <RightBlocks town={town} adTargets={buildAdTargets(town, null)} />
          </div>
        )}
      </div>

      <Footer {...footer_props} />
    </>
  );
};

Pages.propTypes = {
  page: object,
  masthead_props: object,
  footer_props: object,
  town: object,
};

export default withTownContextProvider(Pages);
