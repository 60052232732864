import React, { useState, useRef, useEffect, createRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./Article.style";
import ByLine from "../Article/ByLine/ByLine";
import ArticleBody from "./ArticleBody";
import ArticleFooter from "./ArticleFooter/ArticleFooter";
import RelatedArticles from "./RelatedArticles/RelatedArticles";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import withConfig from "../../utils/withConfig";
import Carousel from "../../common/Carousel/Carousel";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../hooks/useMediaQuery";
import labels from "../../config/labels";
import Typography from "../../common/Typography/Typography";
import Item from "../../common/Carousel/Item";
import { getEnvUrl } from "../../utils/helper";
import Ad from "../Ad/Ad";
import Button from "../../common/Button/Button";
import Disclaimer from "./Disclaimer/Disclaimer";
import StickySocialNetworksShareButtons from "../StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import { isMobile } from "react-device-detect";
import VideoAd from "../Ad/VideoAd";
import AudioPlayer from "../AudioPlayer/AudioPlayer";

const AUDIO_TEXT_SELECTOR = "article_audio7";

const Article = ({
  article_column_category,
  is_advertisement,
  column_description,
  column,
  section,
  section_link,
  article_body,
  article_photos,
  recent_articles_nearby,
  ad_free,
  byline,
  published_at,
  town_editor_name,
  town_editor_email,
  category_name,
  category_link,
  subscribe_to_content,
  is_town,
  is_article_preview,
  video_ad_content,
  is_premium,
  title,
  important_data_changed_at,
  promotional_footer,
  disclaimer,
  env,
  towns_with_clusters,
  current_user_id,
  ad_targets,
  town_link,
  town_name,
  submitted_via,
  disclaimer_policy_page_links,
  leaderboard_mobile_ad_name,
  leaderboard_mobile_ad_size,
  town_admins_profile_page_path,
  town_slug,
  article_disclaimer,
  is_license,
  has_audio_player,
}) => {
  const classes = useStyles(isMobile);
  const bodyRef = createRef();
  const [articleBody] = useState(article_body ? article_body : "");
  const [initialTopOffset, setInitialTopOffset] = useState(0);

  const PromotionalFooter = () => {
    return (
      <div
        className={classes.promotional_footer}
        dangerouslySetInnerHTML={{
          __html: promotional_footer,
        }}
      />
    );
  };

  const EditorNotes = () => {
    return (
      <Typography level={"t2_text_2"} italic>
        {labels.EDITORS_NOTES + disclaimer}
      </Typography>
    );
  };

  const ReadMoreTown = () => {
    return (
      <div
        className={clsx(
          classes.container_read_more_town_button,
          classes.no_printing
        )}
      >
        <a href={town_link}>
          <Button level="simple_button" color="primary" bold>
            {is_license
              ? labels.READ_MORE_LICENSE + town_name + labels.NEWS
              : labels.READ_MORE_TOWN + town_name + labels.NEWS}
          </Button>
        </a>
      </div>
    );
  };

  const displayReadMoreTown = () => {
    if (
      town_link !== null &&
      town_link !== undefined &&
      town_name !== null &&
      town_name !== undefined
    ) {
      return true;
    }
    return false;
  };

  // This function calculate X and Y
  const getPosition = () => {
    const y = bodyRef.current.offsetTop;
    setInitialTopOffset(y);
  };

  useEffect(() => {
    getPosition();
  }, [bodyRef]);

  return (
    <>
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={withConfig("RECAPTCHA_KEY")}
        scriptProps={{ async: true, defer: true, appendTo: "body" }}
      >
        {!is_article_preview && <StickySocialNetworksShareButtons />}
        <div className={classes.body_container}>
          <div ref={bodyRef} className={classes.container}>
            <div className={classes.carousel_container}>
              <Carousel items={article_photos} />
            </div>
            <div className={classes.print_article_img}>
              {article_photos && article_photos.length >= 1 && (
                <Item
                  contentUrl={article_photos[0].contentUrl}
                  caption={article_photos[0].caption}
                  credits={article_photos[0].credits}
                  alt_text={article_photos[0].alt_text}
                  isVideo={article_photos[0].isVideo}
                />
              )}
            </div>
            <div className={classes.body_article}>
              {!ad_free && (
                <>
                  {screenWidthIsHigherThan(768) && (
                    <Ad
                      ad_unit={"tap_leaderboard_002"}
                      size={[728, 90]}
                      targets={ad_targets}
                    />
                  )}
                  {screenWidthIsLowerThan(767) && (
                    <Ad
                      ad_unit={leaderboard_mobile_ad_name}
                      size={leaderboard_mobile_ad_size}
                      targets={ad_targets}
                    />
                  )}
                </>
              )}
              {has_audio_player && <AudioPlayer />}
              <ByLine
                author={byline}
                published_at={published_at}
                updated_at={important_data_changed_at}
                author_email={town_editor_email}
                town_admins_profile_page_path={town_admins_profile_page_path}
              />
              {screenWidthIsLowerThan(1024) &&
                video_ad_content &&
                Object.keys(video_ad_content).length > 0 && (
                  <VideoAd videoAd={video_ad_content} />
                )}
              <ArticleBody
                text={articleBody}
                column={column}
                columnDescription={column_description}
                isAdvertisement={is_advertisement}
                articleColumnCategory={article_column_category}
                isAdFree={ad_free}
                categoryName={category_name}
                subscribeToContent={subscribe_to_content}
                isArticlePreview={is_article_preview}
                isTown={is_town}
                isPremium={is_premium}
                adTargets={ad_targets}
                audioTextSelector={AUDIO_TEXT_SELECTOR}
              />
              {/* Disabling tags until it is ready in the CMS */}
              {/* <div>
                <Tags tagged_words={DataToPrint.dataTags} />
              </div> */}
            </div>
            <div className={classes.article_break} />
            <div className={classes.promotional_footer}>
              <PromotionalFooter />
            </div>

            {disclaimer && (
              <div>
                <EditorNotes />
              </div>
            )}
            <div className={classes.disclaimer_block}>
              <Disclaimer
                categoryName={category_name}
                column={column}
                isAdvertisement={is_advertisement}
                articleColumnCategory={article_column_category}
                submittedVia={submitted_via}
                disclaimerPolicyPageLinks={disclaimer_policy_page_links}
                customDisclaimer={article_disclaimer}
              />
            </div>
            {displayReadMoreTown() && !is_article_preview && <ReadMoreTown />}
            {!is_article_preview && (
              <div>
                <ArticleFooter
                  author={town_editor_name}
                  section={section}
                  category_name={category_name}
                  category_link={category_link}
                  section_link={section_link}
                  author_email={town_editor_email}
                  title={title}
                  envUrl={getEnvUrl(env)}
                  townsWithClusters={towns_with_clusters}
                  currentUserId={current_user_id}
                  townSlug={town_slug}
                />
              </div>
            )}
          </div>
          <RelatedArticles articles={recent_articles_nearby} />
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
};

Article.propTypes = {
  section: PropTypes.string,
  section_link: PropTypes.string,
  article_body: PropTypes.string,
  article_photos: PropTypes.array,
  recent_articles_nearby: PropTypes.array,
  column: PropTypes.any,
  column_description: PropTypes.any,
  article_column_category: PropTypes.any,
  is_advertisement: PropTypes.bool,
  ad_free: PropTypes.bool,
  byline: PropTypes.string,
  published_at: PropTypes.string,
  town_editor_name: PropTypes.string,
  town_editor_email: PropTypes.string,
  category_name: PropTypes.string,
  category_link: PropTypes.string,
  subscribe_to_content: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    id: PropTypes.number,
  }),
  is_town: PropTypes.bool,
  is_article_preview: PropTypes.bool,
  video_ad_content: PropTypes.any,
  is_premium: PropTypes.bool,
  title: PropTypes.string,
  important_data_changed_at: PropTypes.string,
  promotional_footer: PropTypes.any,
  env: PropTypes.string,
  towns_with_clusters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name_with_zip_code: PropTypes.string,
      cluster_towns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  current_user_id: PropTypes.number,
  ad_targets: PropTypes.shape({
    towns: PropTypes.arrayOf(PropTypes.string),
    sections: PropTypes.arrayOf(PropTypes.string),
    sponsor_display: PropTypes.arrayOf(PropTypes.string),
  }),
  town_link: PropTypes.string,
  town_name: PropTypes.string,
  disclaimer_policy_page_links: PropTypes.arrayOf(
    PropTypes.shape({
      page: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  leaderboard_mobile_ad_name: PropTypes.string,
  leaderboard_mobile_ad_size: PropTypes.arrayOf(PropTypes.number),
  town_admins_profile_page_path: PropTypes.string,
  town_slug: PropTypes.string,
  article_disclaimer: PropTypes.string,
};

export default Article;
