import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    separator: {
      fontSize: 15,
    },
    breadcrumb: {
      "& span": {
        textTransform: "capitalize",
      },
    },
  };
});

export { useStyles };
