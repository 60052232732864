import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    paragraph: {
      fontFamily: "Montserrat",
      fontSize: "17px",
      "& a": {
        color: theme.palette.miscellaneous.blueLink,
      },
    },
    margin_bottom_: {
      marginBottom: theme.spacing(1),
    },
    bullseye_container: {
      float: "left",
      width: "auto",
      marginRight: theme.spacing(3),
      [theme.breakpoints.down(600)]: {
        marginRight: theme.spacing(2),
        textAlign: "center",
        float: "unset",
        padding: 0,
      },
    },
    standard_container: {
      float: "right",
      width: "auto",
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down(600)]: {
        textAlign: "center",
        float: "unset",
        padding: 0,
      },
    },
    column_description: {
      fontFamily: "Montserrat",
      fontSize: 13,
      fontStyle: "normal",
      fontWeight: 500,
    },
    show_only_in_mobile: {
      [theme.breakpoints.up(600)]: {
        display: "none",
      },
    },
    mobile_newsletter_sing_up_container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

export { useStyles };
