import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  content_container: {
    display: "flex",
    height: 180,
    padding: 0,
    alignItems: "center",
    [`@media print`]: {
      display: "none !important",
    },
  },
  image_container: {
    width: "30%",
    height: 180,
    float: "left",
    marginRight: theme.spacing(2),
    "& img": {
      marginTop: theme.spacing(1),
      maxHeight: 150,
      width: "auto",
      [theme.breakpoints.up(402)]: {
        marginTop: theme.spacing(0.8),
      },
      [theme.breakpoints.down(330)]: {
        marginTop: theme.spacing(0.8),
        maxHeight: 70,
      },
    },
    [theme.breakpoints.down(401)]: {
      marginTop: theme.spacing(0.3),
      maxHeight: 150,
      width: 160,
    },
    [theme.breakpoints.down(330)]: {
      marginTop: theme.spacing(0.3),
      minHeight: 80,
      maxHeight: 180,
      width: "auto",
    },
  },
  border_top_container: {
    borderTop: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content_mobile: {
    [theme.breakpoints.down(401)]: {
      paddingTop: theme.spacing(0.8),
    },
    [theme.breakpoints.up(402)]: {
      paddingTop: theme.spacing(2),
    },
    textAlign: "left",
    [`@media print`]: {
      display: "none !important",
    },
  },
}));

export { useStyles };
