import React, { useEffect } from "react";
import clsx from "clsx";
import { useStyles } from "./AudioPlayer.style";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import labels from "../../config/labels";

const AudioPlayer = () => {

  const classes = useStyles();
  
  const label = labels.GETTING_AUDIO_PLAYER_READY.split("//");

  useAudioPlayer();

  return (
    <div className={clsx("trinity-tts-pb", classes.container)} dir="ltr">
      <strong className={classes.text}>
        {label[0]}
        <a href="//trinityaudio.ai" className={classes.link}>
          {label[1]}
        </a>
        {label[2]}
      </strong>
    </div>
  );
};

export default AudioPlayer;