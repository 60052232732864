import React from "react";
import PropTypes from "prop-types";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { useStyles } from "./RelatedArticles.style";
import ArticleCard from "../ArticleCard/ArticleCard";
import FeaturedArticleCard from "../FeaturedArticleCard/FeaturedArticleCard";
import ReducedCard from "../ArticleCard/ReducedCard";

const RelatedArticles = ({ articles }) => {
  const classes = useStyles();

  if (articles.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className={classes.recent_articles_title}>
        <Typography level="h4_sub_header_4" bold>
          {labels.RECENT_ARTICLES_NEARBY}
        </Typography>
      </div>

      {articles.map((article, index) => (
        <ReducedCard
          photoUrl={article.photo_url}
          articleUrl={article.article_url}
          title={article.name}
          key={`article-card-${index}`}
          noTopBorder={index === 0}
        />
      ))}
    </>
  );
};

RelatedArticles.propTypes = {
  articles: PropTypes.array,
};

export default RelatedArticles;
