import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  featured_article: {
    padding: theme.spacing(2),
    flexBasis: "33%",
  },
  featured_image_container: {
    height: theme.spacing(23),
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "auto",
      width: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
      margin: "auto",
    },
  },
  featured_title_container: {
    width: "100%",
    textAlign: "left",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export { useStyles };
