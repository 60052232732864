import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ArticleFooter.style";
import Typography from "../../../common/Typography/Typography";
import EmailIcon from "../../../common/Icons/EmailIcon";
import Link from "../../../common/Link/Link";
import Button from "../../../common/Button/Button";
import labels from "../../../config/labels";
import clsx from "clsx";
import { isValidString } from "../../../utils/helper";
import SubmitContentModal from "../../Modals/SubmitContentModal/SubmitContentModal";
import { isMobile } from "react-device-detect";

const ArticleFooter = ({
  author,
  author_email,
  section_link,
  category_name,
  category_link,
  section,
  townSlug,
}) => {
  const classes = useStyles(isMobile);
  const [openDialog, setOpenDialog] = useState(false);

  const showGetInTouch = () => {
    return isValidString(author) || isValidString(author_email);
  };

  const printAutorLabel = () => {
    if (isValidString(author)) {
      return author;
    }
    if (isValidString(author_email)) {
      return author_email;
    }
    return "";
  };

  const displayReadMore = () => {
    if (category_name !== null && category_name !== undefined) {
      return true;
    }
    if (section !== null && section !== undefined) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={clsx(classes.row, classes.container_article_footer)}>
        <div className={classes.container_author}>
          <div className={classes.container_left}>
            {showGetInTouch() && (
              <>
                <Typography italic level="paragraph_2">
                  {labels.GET_IN_TOUCH_WITH}
                </Typography>
                <Typography level="paragraph_2" bold>
                  {printAutorLabel()}
                  {labels.ONE_SPACE}
                </Typography>
                <Link url={`mailto:${author_email}`}>
                  <EmailIcon className={classes.mail_icon} fontSize="large" />
                </Link>
              </>
            )}
            {displayReadMore() && (
              <div className={classes.read_more_container}>
                <Link
                  className={clsx(classes.block, classes.margin_bottom)}
                  bold
                  level={"paragraph_2"}
                  url={!section ? category_link : section_link}
                  color="tapintoGreen"
                >
                  <Typography level={"paragraph_2"} color="tapintoGreen">
                    {labels.READ_MORE}
                  </Typography>
                  {!section ? category_name : section}
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className={classes.container_submit_buttons}>
          <a
            className={classes.contribute_content_button}
            href="#"
            onClick={() => setOpenDialog(true)}
          >
            <Button
              className={classes.button_submit}
              level="simple_button"
              color="primary"
              bold
            >
              <Typography bold level="paragraph_2" color="black">
                {labels.CLICK_TO}
                <div className={clsx(classes.block_inline)}>
                  {labels.SUBMIT_CONTENT}
                </div>
              </Typography>
            </Button>
          </a>
        </div>
      </div>

      <SubmitContentModal
        town={{ id: townSlug }}
        openState={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
};

ArticleFooter.propTypes = {
  author: PropTypes.string,
  section_link: PropTypes.string,
  section: PropTypes.string,
  category_name: PropTypes.string,
  category_link: PropTypes.string,
  author_email: PropTypes.string,
  envUrl: PropTypes.string,
  townsWithClusters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name_with_zip_code: PropTypes.string,
      cluster_towns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  currentUserId: PropTypes.number,
  townSlug: PropTypes.string,
};
export default ArticleFooter;
