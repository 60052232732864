import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./Edit.style";
import Typography from "../../common/Typography/Typography";
import Button from "../../common/Button/Button";
import labels from "../../config/labels";
import ContentOptionsPopUp from "./ContentOptionsPopUp/ContentOptionsPopUp";

const Edit = ({ editUrl }) => {
  const classes = useStyles();
  const [openEditContentDialog, setOpenEditContentDialog] = useState(false);

  const onEditClick = () => {
    setOpenEditContentDialog(true);
  };

  return (
    <>
      <div className={classes.content_options_buttons}>
        <Button
          level="simple_button"
          color="primary"
          bold
          onClick={onEditClick}
        >
          <Typography color="tapintoGreen" level="paragraph_2" bold>
            {labels.EDIT}
          </Typography>
        </Button>
      </div>

      <ContentOptionsPopUp
        openPopUp={openEditContentDialog}
        setOpenPopUp={setOpenEditContentDialog}
        url={editUrl}
        type="edit"
      />
    </>
  );
};

Edit.propTypes = {
  editUrl: PropTypes.string.isRequired,
};

export default Edit;
