import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    disclaimer: {
      fontStyle: "italic",
      fontSize: "13px",
      fontFamily: "Montserrat",
      lineHeight: `${theme.spacing(3)}px`,
      "& a": {
        color: theme.palette.miscellaneous.blueLink,
        textDecoration: "none",
      },
    },
  };
});

export { useStyles };
