import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
  },

  sign_up_container: {
    display: "inline-block",
    width: "100%",
    backgroundColor: theme.palette.themeColor.sign_up_background_color,
    border: `3px solid ${theme.palette.themeColor.primary}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "left",
    "& .row .paragraph_2": {
      marginBottom: "unset",
    },
    [`@media print`]: {
      display: "none !important",
    },
  },
  singup_footer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(601)]: {
      marginTop: theme.spacing(1),
      display: "block",
    },
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
  },
  recaptcha_text: {
    flexGrow: 1,
    flexBasis: "auto",
  },
  recaptcha_text_mobile: {
    [theme.breakpoints.down(600)]: {
      fontSize: "12px !important",
    },
  },
  font_title_sub_mobile: {
    [theme.breakpoints.down(600)]: {
      fontSize: "13px !important",
    },
  },
  subscribe_form: {
    [theme.breakpoints.down(600)]: {
      display: "block",
    },
  },
  container_submit_button: {
    flexGrow: 3,
    flexBasis: "auto",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    width: "55%",

    "& button": {
      backgroundColor: theme.palette.themeColor.primary,
      border: `2px solid ${theme.palette.themeColor.primary}`,
      width: "100%",
      height: theme.spacing(4),
      textTransform: "none !important",
      transition: "0s 3s",
      "&:hover": {
        backgroundColor: theme.palette.themeColor.primary,
      },
      "&:active": {
        backgroundColor: theme.palette.tapintoBranding.lightOrange,
        border: `2px solid ${theme.palette.themeColor.primary}`,
        transition: "0s",
      },
      "& span.MuiButton-label": {
        fontSize: "16px",
      },
    },

    [theme.breakpoints.down(600)]: {
      marginTop: theme.spacing(1),
      marginRight: "0",
      marginLeft: "0",
      width: "100% !important",
      "& .MuiButton-root": {
        marginLeft: "0 !important",
        marginRight: "0 !important",
      },
    },
  },
  email: {
    marginTop: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  red_input_border: {
    marginTop: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
      borderWidth: "2px",
    },
  },
  success_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.themeColor.primary,
    height: 100,
    width: "100%",
    border: `3px solid ${theme.palette.themeColor.primary}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    "& .row .paragraph_2": {
      marginBottom: "unset",
    },
    [theme.breakpoints.down(601)]: {
      height: 150,
    },
    [theme.breakpoints.down(401)]: {
      height: 200,
    },
  },
  hidden: {
    display: "none",
  },
  success_message: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: "1rem",
    textAlign: "center",
    [theme.breakpoints.down(601)]: {
      padding: 0,
    },
  },
  error_container: {
    display: "block",
    align: "center",
    textAlign: "center",
    paddingBottom: theme.spacing(0.5),
    borderBottom: "2px solid red",
  },
  error_message: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: "1.2rem",
    color: "red",
  },
  already_subscribed_link: {
    "& span": {
      color: `${theme.palette.themeColor.secondary} !important`,
    },
  },
}));

export { useStyles };
