// Cache keys

// Articles
export const HEADLINE = "HEADLINE";
export const SUBHEADING = "SUBHEADING";
export const FACEBOOK_TOWN_IDS = "FACEBOOK_TOWN_IDS";
export const PHOTO_IDS = "PHOTO_IDS";
export const CONTENT_BODY = "CONTENT_BODY";
export const MAIN_TOWN_IDS = "MAIN_TOWN_IDS";
export const SELECTED_TOWNS = "SELECTED_TOWNS";
export const AUTHORS_NAME = "AUTHORS_NAME";
export const FACEBOOK_EXCERPT = "FACEBOOK_EXCERPT";
export const FACEBOOK_URL = "FACEBOOK_URL";
export const UNSUPPORTED_FACEBOOK_URL = "UNSUPPORTED_FACEBOOK_URL";
export const FORM_TYPE = "FORM_TYPE";
export const CONTRIBUTION_TYPE = "CONTRIBUTION_TYPE";
export const POST_ON_FACEBOOK = "POST_ON_FACEBOOK";
export const NUMBER_FREQUENCY_EVENT = "NUMBER_FREQUENCY_EVENT";
export const NUMBER_OCCURRENCES_EVENT = "NUMBER_OCCURRENCES_EVENT";
export const MULTIPLE_EVENT_FRQUENCY = "MULTIPLE_EVENT_FRQUENCY";
export const MULTIPLE_EVENT_START_DATE = "MULTIPLE_EVENT_START_DATE";
export const MULTIPLE_EVENT_END_DATE = "MULTIPLE_EVENT_END_DATE";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const MANDATORY_PHONE_NUMBER = "MANDATORY_PHONE_NUMBER";
export const VIDEOS = "VIDEOS";
export const CONTENT_ID = "CONTENT_ID";
export const TOWN_OF_RESIDENCY = "TOWN_OF_RESIDENCY";
export const LTE_QUESTION = "LTE_QUESTION";
export const CREDITS_TEXT = "CREDITS_TEXT";
export const CAPTION_TEXT = "CAPTION_TEXT";
export const ALT_TEXT = "ALT_TEXT";
export const VIDEOS_TO_REMOVE = "VIDEOS_TO_REMOVE";

// Events
export const EVENT_NAME = "EVENT_NAME";
export const ADDRESS = "ADDRESS";
export const VENUE = "VENUE";
export const EVENT_DATE_STARTS = "EVENT_DATE_STARTS";
export const EVENT_START_TIME = "EVENT_START_TIME";
export const EVENT_DATE_ENDS = "EVENT_DATE_ENDS";
export const EVENT_END_TIME = "EVENT_END_TIME";
export const EVENT_DATE_ID = "EVENT_DATE_ID";
export const RECURRENT_DAYS_OF_THE_WEEK = "RECURRENT_DAYS_OF_THE_WEEK";
export const CITY = "CITY";
export const STATE = "STATE";
export const ZIPCODE = "ZIPCODE";
export const CI_NAME_ADMIN = "CI_NAME_ADMIN";
export const CI_PHONE_ADMIN = "CI_PHONE_ADMIN";
export const CI_NAME = "CI_NAME";
export const CI_PHONE = "CI_PHONE";
export const CI_EMAIL = "CI_EMAIL";
export const CI_WEBSITE = "CI_WEBSITE";
export const CI_FACEBOOK = "CI_FACEBOOk";
export const CI_TWITTER = "CI_TWITTER";
export const CI_INSTAGRAM = "CI_INSTAGRAM";
export const ALL_DAY = "ALL_DAY";
export const EVENT_DATES = "EVENT_DATES";
export const VIRTUAL_EVENT = "VIRTUAL_EVENT";
export const VIRTUAL_EVENT_URL = "VIRTUAL_EVENT_URL";
export const DATES_TO_REMOVE = "DATES_TO_REMOVE";
export const IS_SAME_CONTACT_INFO = "IS_SAME_CONTACT_INFO";

// Business directory listing
export const CATEGORIES = "CATEGORIES";
export const ORGANIZATION_TYPE = "ORGANIZATION_TYPE";
export const CONTACT_PERSON_PRIVATE = "CONTACT_PERSON_PRIVATE";
export const CONTACT_PERSON = "CONTACT_PERSON";
export const EMAIL = "EMAIL";
export const WEBSITE = "WEBSITE";
export const FACEBOOK = "FACEBOOK";
export const TWITTER = "TWITTER";
export const DIRECTORY_ID = "DIRECTORY_ID";

//Real Estate Listing
export const PROPERTY_NAME = "PROPERTY_NAME";
export const BEDROOMS = "BEDROOMS";
export const BATHROOMS = "BATHROOMS";
export const LOT_SIZE = "LOT_SIZE";
export const PRICE = "PRICE";
export const MLS_NUMBER = "MLS_NUMBER";
export const MLS_URL = "MLS_URL";
export const LISTING_URL = "LISTING_URL";
export const REALTOR_NAME = "REALTOR_NAME";
export const AGENCY_NAME = "AGENCY_NAME";
export const AGENCY_PHONE_NUMBER = "AGENCY_PHONE_NUMBER";
export const SOLD = "SOLD";
export const FOR_RENT = "FOR_RENT";
export const FOR_SALE = "FOR_SALE";
export const UNDER_CONTRACT = "UNDER_CONTRACT";
export const REAL_ESTATE_ID = "REAL_ESTATE_ID";
export const TOWN = "TOWN";

export const DAYS_OF_THE_WEEK = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];
export const FULL_NAME_DAYS_OF_THE_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const MONTHS_OF_THE_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const DEFAULT_EVENT_REPETITION_FREQUENCIES = ["Days", "Weeks", "Months"];
export const OK = 200;
export const GREEN = "tapintoGreen";
export const GRAY = "gray";
export const BLACK = "black";
export const WHITE = "white";
export const LIGHT_GRAY = "lightgray";
export const FACEBOOK_BLUE = "facebookBlue";
export const TWITTER_BLUE = "twitterBlue";
export const X_BLACK = "#000000";
export const INSTAGRAM_PINK = "instagramPink";
export const BLUE_LINK = "blueLink";
export const ORANGE = "tapintoOrange";
export const ORANGE_HEX = "#F98C10";
export const GREEN_HEX = "#7fc035";
export const VERY_LIGHT_GRAY = "grayDivider";
export const COLUMN_ID = "COLUMN_ID";
export const CATEGORY_IDS = {
  news: 27,
  candidate_statements: 28,
  letters_to_the_editor: 33,
  column: 957,
  freelance: 78,
};

//Obituary
export const NAME_OF_DECEASED = "NAME_OF_DECEASED";
export const VISITATION_DATE = "VISITATION_DATE";
export const DATE_OF_BIRTH = "DATE_OF_BIRTH";
export const DATE_OF_DEATH = "DATE_OF_DEATH";
export const VISITATION_START_TIME = "VISITATION_START_TIME";
export const VISITATION_END_TIME = "VISITATION_END_TIME";
export const FUNERAL_HOME_NAME = "FUNERAL_HOME_NAME";
export const SERVICE_DATE = "SERVICE_DATE";
export const SERVICE_START_TIME = "SERVICE_START_TIME";
export const SERVICE_END_TIME = "SERVICE_END_TIME";
export const BURIAL_DATE = "BURIAL_DATE";
export const BURIAL_START_TIME = "BURIAL_START_TIME";
export const BURIAL_END_TIME = "BURIAL_END_TIME";
export const CREMATION_DATE = "CREMATION_DATE";
export const CREMATION_START_TIME = "CREMATION_START_TIME";
export const CREMATION_END_TIME = "CREMATION_END_TIME";
export const RADIO_BUTTON_OBITUARY_VALUE = "RADIO_BUTTON_OBITUARY_VALUE";
export const VISITATION_INFO = "VISITATION_INFO";
export const SERVICE_INFO = "SERVICE_INFO";
export const BURIAL_INFO = "BURIAL_INFO";
export const CREMATION_INFO = "CREMATION_INFO";
export const OBITUARY_ID = "OBITUARY_ID";

export const ORGANIZATION_TYPES = {
  for_profit: "for_profit",
  political_candidates: "political_candidates",
  non_profit: "nonprofit",
  government: "governmental",
  free: "free",
};

// Form types
export const NEWS = "news";
export const CANDIDATE_STATEMENTS = "candidate-statements";
export const COLUMN = "column";
export const PRESS_RELEASE = "press-release";
export const FREELANCE = "freelance";
export const LETTERS_TO_THE_EDITOR = "letters-to-the-editor";
export const EVENT = "event";
export const MILESTONE = "milestone";
export const MILESTONES = "milestones";
export const OBITUARY = "obituary";
export const OBITUARIES = "obituaries";
export const BUSINESS_DIRECTORY_LISTING = "business-directory-listing";
export const BUSINESS_LISTINGS = "business_listings";
export const CLASSIFIED = "classified";
export const CLASSIFIEDS = "classifieds";
export const CLASSIFIED_ID = "CLASSIFIED_ID";
export const REAL_ESTATE_LISTING = "real-estate-listing";
export const REAL_ESTATE_LISTINGS = "real_estate_listings";

// CACHE KEYS
export const BUSINESS_NAME = "BUSINESS_NAME";
export const BUSINESS_ADDRESS = "BUSINESS_ADDRESS";
export const BUSINESS_ADDRESS2 = "BUSINESS_ADDRESS2";
export const ZIP_CODE = "ZIP_CODE";
export const FAX = "FAX";
export const PHONE_NUMBER_PRIVATE = "PHONE_NUMBER_PRIVATE";

// MANDATORY FIELDS
export const MANDATORY_FIELDS = [
  { key: MAIN_TOWN_IDS, error: "Need to select at least one town" },
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: CONTENT_BODY, error: "Body cannot be blank" },
  { key: AUTHORS_NAME, error: "Author's name cannot be blank" },
];

export const MANDATORY_DRAFT_ARTICLE_FIELDS = [
  { key: MAIN_TOWN_IDS, error: "Need to select at least one town" },
  { key: HEADLINE, error: "Headline cannot be empty" },
];

export const MANDATORY_FIELDS_FOR_COLUMN = [
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: COLUMN_ID, error: "Please choose a column" },
];

export const FACEBOOK_MANDATORY_FIELDS = [
  { key: FACEBOOK_EXCERPT, error: " Excerpt is a required field" },
  { key: FACEBOOK_URL, error: " Facebook URL is a required field" },
  {
    key: UNSUPPORTED_FACEBOOK_URL,
    error: " Unsupported Business's Facebook URL",
  },
];
export const MANDATORY_FIELDS_FOR_CLASSIFIED = [
  { key: MAIN_TOWN_IDS, error: "Need to select at least one town" },
  { key: CATEGORIES, error: "Need to select at least one category" },
  { key: HEADLINE, error: "Headline cannot be empty" },
  {
    key: CONTACT_PERSON_PRIVATE,
    error: "Contact person (admin) cannot be empty",
  },
  {
    key: PHONE_NUMBER_PRIVATE,
    error: "Phone Number (admin) cannot be empty",
  },
  { key: CONTENT_BODY, error: "Classified description cannot be blank" },
];
export const MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING = [
  { key: MAIN_TOWN_IDS, error: "Need to select at least one town" },
  { key: BUSINESS_NAME, error: "Business Name cannot be empty" },
  { key: CI_NAME_ADMIN, error: "Contact person cannot be empty" },
  { key: CONTENT_BODY, error: "Business description cannot be blank" },
  { key: CATEGORIES, error: "Need to select at least one category" },
  { key: CI_PHONE_ADMIN, error: "Phone Number cannot be blank" },
  { key: CITY, error: "City cannot be blank" },
  { key: STATE, error: "State cannot be blank" },
  { key: ZIP_CODE, error: "ZIP Code cannot be blank" },
  { key: BUSINESS_ADDRESS, error: "Business Address cannot be blank" },
];
export const MANDATORY_FIELDS_FOR_MILESTONE = [
  { key: MAIN_TOWN_IDS, error: "Need to select at least one town" },
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: CONTACT_PERSON_PRIVATE, error: "Contact person cannot be empty" },
  { key: PHONE_NUMBER_PRIVATE, error: "Contact phone number cannot be empty" },
  { key: EMAIL, error: "Contact email cannot be empty" },
  { key: CONTENT_BODY, error: "Milestone description cannot be blank" },
  { key: CATEGORIES, error: "Need to select at least one category" },
];
export const MANDATORY_FIELDS_FOR_EVENT = [
  { key: MAIN_TOWN_IDS, error: "Need to select at least one town" },
  { key: EVENT_NAME, error: "Event name cannot be empty" },
  { key: CONTENT_BODY, error: "Event description cannot be blank" },
  { key: CATEGORIES, error: "Need to select at least one category" },
  { key: CI_NAME_ADMIN, error: "Contact Person info is required" },
  { key: CI_PHONE_ADMIN, error: "Phone Number cannot be blank" },
];
export const MANDATORY_FIELDS_FOR_EVENT_UPDATE_FORM = [
  { key: EVENT_NAME, error: "Event name cannot be empty" },
  { key: CONTENT_BODY, error: "Event description cannot be blank" },
  { key: CATEGORIES, error: "Need to select at least one category" },
  { key: CI_NAME_ADMIN, error: "Contact Person info is required" },
  { key: CI_PHONE_ADMIN, error: "Phone Number cannot be blank" },
];
export const MANDATORY_PHONE_NUMBER_ERROR = "Phone number cannot be blank";
export const MANDATORY_FIELDS_FOR_OBITUARY = [
  { key: MAIN_TOWN_IDS, error: "Need to select at least one town" },
  { key: NAME_OF_DECEASED, error: "Name of deceased cannot be empty" },
  { key: CONTENT_BODY, error: "Obituary content cannot be blank" },
];
export const MANDATORY_FIELDS_FOR_REAL_ESTATE = [
  { key: MAIN_TOWN_IDS, error: "Need to select at least one town" },
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: CONTENT_BODY, error: "Body cannot be blank" },
  { key: CATEGORIES, error: "Need to select at least one category" },
  { key: STATE, error: "State cannot be blank" },
  { key: TOWN, error: "Town cannot be blank" },
  { key: ADDRESS, error: "Address cannot be blank" },
  { key: ZIPCODE, error: "ZIP Code cannot be blank" },
  { key: PRICE, error: "Price cannot be blank" },
];
export const MANDATORY_FIELDS_FOR_REAL_ESTATE_UPDATE_FORM = [
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: CONTENT_BODY, error: "Body cannot be blank" },
  { key: CATEGORIES, error: "Need to select at least one category" },
  { key: STATE, error: "State cannot be blank" },
  { key: TOWN, error: "Town cannot be blank" },
  { key: ADDRESS, error: "Address cannot be blank" },
  { key: PRICE, error: "Price cannot be blank" },
];
export const MANDATORY_TOWN_OF_RESIDENCY_ERROR =
  "Town of Residency is a mandatory field";
export const MANDATORY_FIELDS_FOR_EVENT_PREVIEW = [
  { key: EVENT_NAME, error: "Event name cannot be empty" },
];
export const MANDATORY_FIELDS_FOR_OBITUARY_PREVIEW = [
  { key: NAME_OF_DECEASED, error: "Name of deceased cannot be empty" },
];
export const MORE_THAN_TWENTY_TOWNS_ERROR =
  "Please select up to 20 sites (towns)";

export const MANDATORY_FIELDS_FOR_ARTICLE_UPDATE = [
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: CONTENT_BODY, error: "Body cannot be blank" },
  { key: AUTHORS_NAME, error: "Author's name cannot be blank" },
];

export const MANDATORY_FIELDS_FOR_CLASSIFIED_UPDATE = [
  { key: CATEGORIES, error: "Need to select at least one category" },
  { key: HEADLINE, error: "Headline cannot be empty" },
  {
    key: CONTACT_PERSON_PRIVATE,
    error: "Contact person (admin) cannot be empty",
  },
  {
    key: PHONE_NUMBER_PRIVATE,
    error: "Phone Number (admin) cannot be empty",
  },
  { key: CONTENT_BODY, error: "Classified description cannot be blank" },
];

export const MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING_UPDATE = [
  { key: BUSINESS_NAME, error: "Business Name cannot be empty" },
  { key: CI_NAME_ADMIN, error: "Contact person cannot be empty" },
  { key: CONTENT_BODY, error: "Business description cannot be blank" },
  { key: CATEGORIES, error: "Need to select at least one category" },
  { key: CI_PHONE_ADMIN, error: "Phone Number cannot be blank" },
  { key: CITY, error: "City cannot be blank" },
  { key: STATE, error: "State cannot be blank" },
  { key: ZIP_CODE, error: "ZIP Code cannot be blank" },
  { key: BUSINESS_ADDRESS, error: "Business Address cannot be blank" },
];

export const MANDATORY_FIELDS_FOR_MILESTONE_UPDATE = [
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: CONTACT_PERSON_PRIVATE, error: "Contact person cannot be empty" },
  { key: PHONE_NUMBER_PRIVATE, error: "Contact phone number cannot be empty" },
  { key: EMAIL, error: "Contact email cannot be empty" },
  { key: CONTENT_BODY, error: "Milestone description cannot be blank" },
  { key: CATEGORIES, error: "Need to select at least one category" },
];

export const MANDATORY_FIELDS_FOR_OBITUARY_UPDATE = [
  { key: NAME_OF_DECEASED, error: "Name of deceased cannot be empty" },
  { key: CONTENT_BODY, error: "Obituary content cannot be blank" },
];

export const MANDATORY_NON_PROFIT_CHECKBOX =
  "Must certify that you are submitting this content for or on behalf of a 501(c)(3) nonprofit organization";

export const AUTHORS_NAME_CANNOT_BE_ANONYMOUS_ERROR =
  "Author's name cannot be or contain the word 'anonymous'";

export const REMOVABLE_SERVICES_ID = "REMOVABLE_SERVICES_ID";
export const ITEMS_COUNT = "ITEMS_COUNT";
export const NON_PROFIT_CHECKBOX = "NON_PROFIT_CHECKBOX";

export const MANDATORY_FIELDS_FOR_PREVIEW = [
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: CONTENT_BODY, error: "Body cannot be blank" },
  { key: AUTHORS_NAME, error: "Author's name cannot be blank" },
];

export const MANDATORY_FIELDS_FOR_REAL_ESTATE_PREVIEW = [
  { key: HEADLINE, error: "Headline cannot be empty" },
  { key: CONTENT_BODY, error: "Body cannot be blank" },
];

export const DIY_FREE_CONTENT_FORMS = [
  LETTERS_TO_THE_EDITOR,
  FREELANCE,
  COLUMN,
  MILESTONE,
];
export const CONTENTS_WITH_BOTTOM_DISCLAIMER = [
  CANDIDATE_STATEMENTS,
  LETTERS_TO_THE_EDITOR,
];

export const MANDATORY_FIELDS_FOR_CONTENT_DRAFT = [
  { key: HEADLINE, error: "Headline cannot be empty" },
];

export const MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING_DRAFT = [
  { key: BUSINESS_NAME, error: "Business Name cannot be empty" },
];

export const MANDATORY_FIELDS_FOR_EVENT_DRAFT = [
  { key: EVENT_NAME, error: "Event name cannot be empty" },
];

export const MANDATORY_FIELDS_FOR_OBITUARY_DRAFT = [
  { key: NAME_OF_DECEASED, error: "Name of deceased cannot be empty" },
];

export const VIRTUAL_EVENT_PLACEHOLDER = "https://www.example.com";

export const MANDATORY_TOWN_ERROR = "Select a town for publishing";

export const LETTERS_TO_THE_EDITOR_URL =
  "https://tapinto.net/pages/letters-to-the-editor-policy";

// Legal notices
export const LEGAL_NOTICE = "legal_notice";
export const LEGAL_ENTITY_ID = "legal_entity_id";
export const SCHEDULED_DATES = "scheduled_dates";
export const LEGAL_NOTICE_NAME = "legal_notice_name";
export const MANDATORY_FIELDS_FOR_LEGAL_NOTICE = [
  { key: LEGAL_NOTICE_NAME, error: "Legal notice name cannot be empty" },
];
