import React from "react";
import PropTypes from "prop-types";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { useStyles } from "./EventBlock.style";
import AddToCalendarButton from "../../../common/AddToCalendarButton/AddToCalendarButton";
import { getCategoriesLinks, isValidTime } from "../../../utils/helper";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import ListTypeContentBlock from "../../ContentBlocks/ListType/ListTypeContentBlock";
import SocialNetworkShareButtons from "../../SocialNetworkShareButtons/SocialNetworkShareButtons";

const EventBlock = ({ event, townSlug }) => {
  const classes = useStyles();

  const parseCategories = () => {
    return getCategoriesLinks(
      event.categories || [],
      townSlug,
      false,
      {
        className: classes.categories,
        level: "t1_text_1",
      },
      "events"
    );
  };

  return (
    <ListTypeContentBlock
      contentData={{
        name: event.title,
        path: event.event_url,
        media: {
          videoUrl: event.video_url,
          photoUrl: event.photo_url,
          photoAltText: event.photo_alt_text,
        },
      }}
      body={
        <>
          <Typography level="t1_text_1" color="gray" bold>
            {event.all_day ? event.starts_for_all_day : event.starts}
          </Typography>

          <Typography level="t1_text_1" color="black" bold>
            {event.all_day
              ? labels.ALL_DAY
              : isValidTime(event.start_time) && isValidTime(event.end_time)
              ? event.start_time + labels.EM_DASH + event.end_time
              : ""}
          </Typography>

          <Typography
            level="t1_text_1"
            color="black"
            className={classes.address}
          >
            {event.virtual_event ? labels.VIRTUAL_EVENT : event.address}
          </Typography>

          <div className={classes.categories_container}>
            {parseCategories()}
          </div>
        </>
      }
      shouldShowReadMoreLink={true}
      bottomActions={
        <>
          <div className={classes.bottom_section}>
            <div className={classes.add_to_calendar}>
              <AddToCalendarButton
                starts={event.add_to_calendar_starts}
                ends={event.add_to_calendar_ends}
                title={event.title}
                description={event.description}
                address={event.address}
                timezone={event.timezone}
                textProps={{
                  color: "white",
                  endIconColor: "white",
                  bold: true,
                  level: screenWidthIsLowerThan(1080)
                    ? "t1_text_1"
                    : "t2_text_2",
                }}
              />
            </div>
            <div className={classes.social_networks_sharing}>
              <SocialNetworkShareButtons
                url={event.event_url}
                title={event.title}
                socialNetworks={["facebook", "twitter"]}
                iconsSize={25}
                paddingWithinButtons={10}
              />
            </div>
          </div>
        </>
      }
    />
  );
};

EventBlock.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    timezone: PropTypes.string,
    starts: PropTypes.string,
    starts_for_all_day: PropTypes.string,
    ends: PropTypes.string,
    address: PropTypes.string,
    categories: PropTypes.array,
    all_day: PropTypes.bool,
    images: PropTypes.shape({
      contentUrl: PropTypes.string,
      alt_text: PropTypes.string,
    }),
    slug: PropTypes.string,
  }),
  townSlug: PropTypes.string,
};

export default EventBlock;
