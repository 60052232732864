import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    content_options_buttons: {
      display: "flex",
      justifyContent: "right",
      "& button.MuiButton-root": {
        height: theme.spacing(4),
        width: theme.spacing(11),
        border: `2px solid ${theme.palette.themeColor.secondary}`,
        "& .MuiButton-label": {
          textTransform: "none",
        },
      },
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
      [theme.breakpoints.down(960)]: {
        display: "block",
        float: "none",
        marginTop: theme.spacing(2),
      },
      [`@media print`]: {
        display: "none",
      },
    },
  };
});

export { useStyles };
