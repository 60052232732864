import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  mail_icon: {
    color: theme.palette.themeColor.primary,
    verticalAlign: "middle",
    paddingLeft: theme.spacing(0.5),
    display: "none !important",
  },
  container_by_line: {
    marginBottom: "1%",

    [theme.breakpoints.down(601)]: {
      marginBottom: "3%",
    },
  },
  byline_dates: {
    textAlign: "right",
    marginLeft: theme.spacing(4),
    [`@media print`]: {
      textAlign: "left",
      marginLeft: "0px",
    },
  },

  byline_dates_small: {
    textAlign: "left",
  },
  margin_left: {
    marginLeft: theme.spacing(1),
  },
  min_width_breakpoint_sm: {
    minWidth: 280,
  },
}));

export { useStyles };
