import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container_title: {
      padding: "1vh 0 1vh 0",
      [theme.breakpoints.down(1370)]: {
        paddingTop: "1vh",
        marginBottom: 0,
        fontSize: "30px !important",
        lineHeight: "36px",
      },
      [theme.breakpoints.down(420)]: {
        paddingTop: "16px",
        fontSize: "28px !important",
        lineHeight: "30px",
      },
    },
    container_title_mobile: {
      paddingTop: "16px",
      fontSize: "28px !important",
      lineHeight: "30px",
    },
    subheading_article: {
      padding: "1vh 0 1vh 0",
      [theme.breakpoints.down(1370)]: {
        paddingTop: 0,
        fontSize: "24px !important",
        lineHeight: "26px",
      },
      [theme.breakpoints.down(420)]: {
        fontSize: "22px !important",
        lineHeight: "24px",
      },
    },
    subheading_article_mobile: {
      fontSize: "22px !important",
    },
    body_container: {
      padding: 10,
    },
    container: {
      width: "100%",
    },

    border_top_bottom: {
      borderTop: `3px solid ${theme.palette.grayVariation.grayForBorder}`,
      borderBottom: `3px solid ${theme.palette.grayVariation.grayForBorder}`,
      paddingTop: 5,
    },
    heading_h2: {
      padding: "8px 0 8px 0",
    },
    heading_category: {
      textAlign: "right",
    },
    sponsored_content: {
      padding: theme.spacing(3),
      paddingBottom: 0,
      paddingRight: 0,
      display: "block",
      textAlign: "right",
      opacity: "0.7",
    },
    with_edit_button: {
      display: "flex",
      [theme.breakpoints.down(600)]: {
        display: "block",
      },
    },
    title_and_heading_container: {
      display: "block",
    },
    splitted: {
      width: "85%",
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
    },
    edit_button_container: {
      width: "15%",
      padding: "2vh 0 1vh 0",
      textAlign: "right",
      [theme.breakpoints.down(600)]: {
        width: "100%",
        textAlign: "center",
        padding: "0 0 1vh 0",
      },
    },
  };
});

export { useStyles };
