import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  text_align_left: {
    textAlign: "left",
  },
  font_10_px: {
    justifyContent: "flex-end",
    fontSize: 10,
  },
  margin_bottom: {
    marginBottom: theme.spacing(1),
  },
  article_container: {
    marginTop: theme.spacing(2),
  },
  content_container: {
    display: "flex",
    marginTop: theme.spacing(1),
  },
  image_container: {
    width: "30%",
    float: "left",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  content: {
    width: "100%",
    [`@media print`]: {
      display: "none !important",
    },
  },
  byline: {
    display: "block",
  },
  published_date: {
    display: "block",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(2),
  },
  paragraph: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    "& a": {
      color: theme.palette.miscellaneous.blueLink,
    },
  },
  border_top_container: {
    borderTop: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
    paddingTop: theme.spacing(1),
  },
  content_mobile: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    [`@media print`]: {
      display: "none !important",
    },
  },
  featured_image: {
    width: "50%",
  },
}));

export { useStyles };
