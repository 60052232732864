import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  container_left: {
    marginBottom: theme.spacing(1),
    textAlign: "left",
    lineHeight: "16px",
    [theme.breakpoints.down(601)]: {
      textAlign: "center",
      textAlignLast: "center",
      margin: "0",
    },
  },
  mail_icon: {
    color: theme.palette.themeColor.primary,
    verticalAlign: "middle",
  },
  block_inline: {
    display: "block",
    lineHeight: "0.5",
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down(601)]: {
      display: "inline",
      paddingLeft: theme.spacing(0.5),
    },
  },
  block: {
    lineHeight: "normal",
    display: "block",
  },
  container_submit_buttons: {
    flexGrow: 3,
    flexBasis: "auto",
    width: 220,
    marginBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    textAlign: "right",
    "& button:first-child": {
      background: "white",
      border: "3px solid",
      borderColor: theme.palette.themeColor.secondary,
    },
    [theme.breakpoints.down(601)]: {
      marginLeft: "0",
      width: "100%",
      "& .MuiButton-root": {
        marginRight: "0 !important",
        marginLeft: "0 !important",
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down(401)]: {
      "& .MuiButton-root": {
        width: "93%",
        marginRight: "0 !important",
        marginLeft: "0 !important",
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down(1139)]: {
      paddingLeft: (isMobile) => (isMobile ? 0 : theme.spacing(3)),
    },
  },

  row: {
    display: "flex",
  },
  button_submit: {
    width: 168,
    height: 46,
    padding: "6px 0px !important",
    textTransform: "none !important",
    "&.MuiButton-root": {
      paddingTop: "3px !important",
      margin: 0,
    },
    "&.MuiButton-root .MuiButton-label": {
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.down(601)]: {
      width: "100%",
      height: 41,
      textTransform: "none !important",
      marginBottom: "16px !important",
    },
    [theme.breakpoints.up(600)]: {
      " & .MuiButton-label": {
        paddingBottom: "inherit",
      },
    },
  },
  container_article_footer: {
    marginTop: theme.spacing(2),
    clear: "both",
    [theme.breakpoints.down(601)]: {
      marginTop: theme.spacing(1),
      display: "block",
    },
    [`@media print`]: {
      display: "none !important",
    },
  },
  container_author: {
    flexGrow: 1,
    flexBasis: "auto",
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down(601)]: {
      paddingTop: "0",
    },
  },
  read_more_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down(1139)]: {
      paddingLeft: (isMobile) => (isMobile ? 0 : theme.spacing(3)),
    },
  },
}));

export { useStyles };
