import React from "react";
import PropTypes from "prop-types";
import Link from "../../../common/Link/Link";
import { useStyles } from "./ReducedCard.style";

import clsx from "clsx";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../../hooks/useMediaQuery";

const ReducedCard = ({ articleUrl, photoUrl, title, noTopBorder }) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={clsx(
          classes.content_container,
          noTopBorder ? "" : classes.border_top_container
        )}
      >
        <div className={classes.content_mobile}>
          <div className={classes.image_container}>
            <Link url={articleUrl}>
              <img src={photoUrl} />
            </Link>
          </div>
          {screenWidthIsLowerThan(401) && (
            <Link url={articleUrl} level={"medium_15_px"} bold>
              {title}
            </Link>
          )}
          {screenWidthIsHigherThan(402) && (
            <Link url={articleUrl} level={"medium_18_px"} bold>
              {title}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

ReducedCard.propTypes = {
  photoUrl: PropTypes.string,
  articleUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ReducedCard;
