import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    fixed_cart: {
      fontFamily: "Montserrat",
      color: theme.palette.miscellaneous.black,
      textAlign: "center",
      border: `1px solid ${theme.palette.dark.gray}`,
      borderRadius: theme.spacing(1),
      width: "21%",
      height: "auto",
      zIndex: 1,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(0.2)}px ${theme.spacing(
        3
      )}px ${theme.spacing(0.2)}px`,
      position: "fixed",
      background: theme.palette.miscellaneous.white,
      [`@media (max-height: 745px)`]: {
        height: "-webkit-fill-available",
        overflow: "auto",
      },
      [theme.breakpoints.down(1300)]: {
        width: "27%",
      },
      [theme.breakpoints.down(1300)]: {
        width: "30%",
      },
    },
    popup_cart: {
      fontFamily: "Montserrat",
      color: theme.palette.miscellaneous.black,
      textAlign: "center",
      width: theme.spacing(37.5),
      maxHeight: "70%",
      overflowY: "scroll",
      padding: `${theme.spacing(1.5)}px ${theme.spacing(0.2)}px ${theme.spacing(
        3
      )}px ${theme.spacing(0.2)}px`,
      background: theme.palette.miscellaneous.white,
    },
    disclaimer_container: {
      color: theme.palette.dark.gray,
      textAlign: "left",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(3),
      lineHeight: 1,
    },
    agreement_clause_container: {
      textAlign: "left",
    },
    agreement_clause_radiobutton_container: {
      width: "15%",
      display: "inline-block",
      verticalAlign: "top",
      "& div.MuiFormControl-root": {
        "& div.MuiFormGroup-root": {
          "& label.MuiFormControlLabel-root": {
            marginRight: 0,
            paddingLeft: theme.spacing(1),
          },
        },
      },
    },
    agreement_clause_text: {
      width: "85%",
      display: "inline-block",
      width: "75%",
      fontSize: "11px",
      fontWeight: 700,
      "& a": {
        color: theme.palette.miscellaneous.blueLink,
        fontSize: "11px",
        fontWeight: 700,
        textDecoration: "underline",
      },
    },
    spinner_overlay: {
      position: "fixed",
      background: "rgba(0, 0, 0, 0.9)",
    },
    spinner: {
      top: "45%",
      left: "49%",
    },
    with_top_margin: {
      marginTop: theme.spacing(6),
    },
  };
});

export { useStyles };
