import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ObituariesHeader from "../../../components/Obituary/ObituariesHeader/ObituariesHeader";
import { useStyles } from "./ObituaryBody.style";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import Link from "../../../common/Link/Link";
import FloatingLeftArrowBack from "../../../common/Icons/FloatingLeftArrowBack";
import ServicesAndVisitationsSection from "../ServicesAndVisitationsSection/ServicesAndVisitationsSection";
import Carousel from "../../../common/Carousel/Carousel";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../../hooks/useMediaQuery";
import StickySocialNetworksShareButtons from "../../StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import { getObituaryServices, printDates } from "../../../services/obituaries";
import { getObituaryServicesPreview } from "../../../services/obituaries";
import Edit from "../../Actions/Edit";
import clsx from "clsx";
import Sponsor from "../../Sponsor/Sponsor";

const ObituaryBody = ({
  obituary,
  backToIndexUrl,
  handleSearch,
  setObituariesData,
  availableNames,
  townId,
  townSlug,
  canEdit,
  editUrl,
  isObituaryPreview,
  obituaryPreviewServices,
  sponsorInfo,
}) => {
  const classes = useStyles();
  const [obituaryServices, setObituaryServices] = useState([]);
  const [obituaryServicesPreview, setObituaryServicesPreview] = useState([]);

  useEffect(() => {
    getObituaryServices(obituary.id, setObituaryServices);
  }, []);
  useEffect(() => {
    getObituaryServicesPreview(
      obituaryPreviewServices,
      setObituaryServicesPreview
    );
  }, []);

  const printSponsor = () => {
    if (sponsorInfo !== null) {
      return <Sponsor sponsor_info={sponsorInfo} />;
    }
  };

  return (
    <div className={classes.obituary_show_container}>
      {!isObituaryPreview && (
        <StickySocialNetworksShareButtons
          socialNetworks={["facebook", "twitter", "print"]}
        />
      )}
      <div className={classes.top_section}>
        <div
          className={
            obituaryServices.length > 0 || obituaryServicesPreview.length > 0
              ? classes.top_container_left_section
              : classes.full_width
          }
        >
          {printSponsor()}
          {!isObituaryPreview && (
            <ObituariesHeader
              className={classes.header}
              onSubmitSearch={handleSearch}
              setObituariesData={setObituariesData}
              availableNames={availableNames}
              townId={townId}
              townSlug={townSlug}
              isShowPage={true}
            />
          )}

          <div
            className={clsx(
              classes.title_container,
              canEdit ? classes.splitted : ""
            )}
          >
            <Typography level="h2_header_2" bold className={classes.title}>
              {obituary.name}
            </Typography>
            {canEdit && (
              <div className={classes.edit_button_container}>
                <Edit editUrl={editUrl} />
              </div>
            )}
          </div>

          <div className={classes.date_container}>
            <Typography level="h2_header_2">
              {printDates({
                birth: obituary?.date_of_birth,
                death: obituary?.date_of_death,
              })}
            </Typography>
          </div>

          <div className={classes.image_container}>
            <Carousel items={obituary.media_content} />
          </div>
        </div>

        {screenWidthIsHigherThan(961) &&
          obituaryServices.length > 0 &&
          !isObituaryPreview && (
            <div className={classes.top_container_right_section}>
              <ServicesAndVisitationsSection
                obituaryServices={obituaryServices}
                obituaryName={obituary.name}
              />
            </div>
          )}

        {screenWidthIsHigherThan(961) &&
          obituaryServicesPreview.length > 0 &&
          isObituaryPreview && (
            <div className={classes.top_container_right_section}>
              <ServicesAndVisitationsSection
                obituaryServices={obituaryServicesPreview}
                obituaryName={obituary.name}
                isObituaryPreview={isObituaryPreview}
              />
            </div>
          )}
      </div>

      <div
        className={classes.description}
        dangerouslySetInnerHTML={{
          __html: obituary.description,
        }}
      />

      {screenWidthIsLowerThan(960) && !isObituaryPreview && (
        <>
          {obituaryServices.length > 0 && (
            <div className={classes.top_container_right_section}>
              <ServicesAndVisitationsSection
                obituaryServices={obituaryServices}
                obituaryName={obituary.name}
                isObituaryPreview={isObituaryPreview}
              />
            </div>
          )}
        </>
      )}

      {screenWidthIsLowerThan(960) && isObituaryPreview && (
        <>
          {obituaryServicesPreview.length > 0 && (
            <div className={classes.top_container_right_section}>
              <ServicesAndVisitationsSection
                obituaryServices={obituaryServicesPreview}
                obituaryName={obituary.name}
              />
            </div>
          )}
        </>
      )}

      {!isObituaryPreview && (
        <div className={classes.return_to_index_container}>
          <Link
            url={backToIndexUrl}
            children={
              <div className={classes.return_to_index}>
                <FloatingLeftArrowBack fillColor="#4f4f4f" />
                <Typography level="paragraph_2" color="gray" bold>
                  {labels.RETURN_TO_INDEX_PAGE}
                </Typography>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

ObituaryBody.propTypes = {
  obituary: PropTypes.shape({
    id: PropTypes.number,
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    name: PropTypes.string,
    date_of_birth: PropTypes.string,
    date_of_death: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
  }),
  backToIndexUrl: PropTypes.string,
  handleSearch: PropTypes.func,
  setObituariesData: PropTypes.func,
  availableNames: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  townId: PropTypes.number,
  townSlug: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  editUrl: PropTypes.string,
  isObituaryPreview: PropTypes.bool,
  sponsorInfo: PropTypes.shape({
    page_type: PropTypes.string,
    sponsor_attr: PropTypes.string,
    top_local_news: PropTypes.bool,
    town: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    categories_slug: PropTypes.arrayOf(PropTypes.string),
    columns_slug: PropTypes.arrayOf(PropTypes.string),
    sections_slug: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ObituaryBody;
