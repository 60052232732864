
import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
    container: {
        font: "12px / 18px Verdana, Arial",
        height: "80px",
        lineHeight: "80px",
        margin: "0 0 0 82px"
    },
    link: {
        color: "#4b4a4a",
        textDecoration: "none",
        fontWeight: 700,
    },
    text: {
        fontWeight: 400,
    },
}));

export { useStyles };