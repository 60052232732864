import { Block } from "@material-ui/icons";
import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    body_container: {
      padding: 10,
      paddingLeft: (isMobile) => (isMobile ? 0 : 10),
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },

    border_top_bottom: {
      borderTop: `3px solid ${theme.palette.grayVariation.grayForBorder}`,
      borderBottom: `3px solid ${theme.palette.grayVariation.grayForBorder}`,
    },
    carousel_container: {
      maxWidth: "100%",
      position: "relative",
      [theme.breakpoints.down(1120)]: {
        paddingLeft: (isMobile) =>
          isMobile ? theme.spacing(1) : theme.spacing(3),
      },
    },
    body_article: {
      padding: 10,
      paddingLeft: 0,
      maxWidth: 770,
      [theme.breakpoints.down(600)]: {
        padding: 10,
        paddingLeft: 0,
        maxWidth: 770,
        position: "relative",
      },
      [theme.breakpoints.down(1120)]: {
        paddingLeft: (isMobile) =>
          isMobile ? theme.spacing(1) : theme.spacing(3),
      },
    },
    article_break: {
      width: "250px",
    },
    no_display: {
      display: "none",
    },
    disclaimer_block: {
      display: "inline-block !important",
      [theme.breakpoints.down(601)]: {
        marginLeft: theme.spacing(2.8),
        marginRight: theme.spacing(1.5),
        marginBottom: theme.spacing(3),
      },
    },
    container_read_more_town_button: {
      flexGrow: 3,
      flexBasis: "auto",
      marginTop: theme.spacing(2),
      width: "100%",
      "& button": {
        backgroundColor: theme.palette.themeColor.primary,
        border: `2px solid ${theme.palette.themeColor.primary}`,
        width: "100%",
        textTransform: "none !important",
        transition: "0s 3s",
        "&:hover": {
          backgroundColor: theme.palette.themeColor.primary,
        },
        "&:active": {
          backgroundColor: theme.palette.tapintoBranding.lightOrange,
          border: `2px solid ${theme.palette.themeColor.primary}`,
          transition: "0s",
        },
        "& span.MuiButton-label": {
          fontSize: "16px",
        },
      },

      [theme.breakpoints.down(600)]: {
        marginTop: theme.spacing(1),
        marginRight: "0",
        marginLeft: "0",
        width: "100% !important",
        "& .MuiButton-root": {
          marginLeft: "0 !important",
          marginRight: "0 !important",
        },
      },
      [theme.breakpoints.down(1139)]: {
        paddingLeft: (isMobile) => (isMobile ? 0 : theme.spacing(3)),
      },
    },
    no_printing: {
      "@media print": {
        display: "none",
      },
    },
    video_ad_container: {
      display: "block !important",
      "& > div": {
        marginBottom: "20px !important",
      },
      "& > #videoContainer > #videoContent": {
        top: 5,
      },
      "& > #videoContainer > #adContainer": {
        marginTop: "20px",
      },
    },
    print_article_img: {
      display: "none",
      [`@media print`]: {
        display: "block !important",
        width: "100%",
        height: "auto",
      },
    },
    promotional_footer: {
      fontFamily: "Montserrat",
      fontSize: "17px",
      fontWeight: 400,
      lineHeight: 1.6,
      textRendering: "optimizeLegibility",
      marginBottom: theme.spacing(2),
      "& a": {
        color: theme.palette.miscellaneous.blueLink,
      },
      [theme.breakpoints.down(1139)]: {
        paddingLeft: (isMobile) => (isMobile ? 0 : theme.spacing(2)),
      },
    },
  };
});

export { useStyles };
