import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  recent_articles_title: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.light.grey,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    [`@media print`]: {
      display: "none !important",
    },
  },
  main_articles: {
    display: "flex",
    width: "100%",
    lineHeight: 1,
    [theme.breakpoints.down(768)]: {
      display: "block",
    },
    [`@media print`]: {
      display: "none !important",
    },
  },
}));

export { useStyles };
