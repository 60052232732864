import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ByLine.style";
import Typography from "../../../common/Typography/Typography";
import EmailIcon from "../../../common/Icons/EmailIcon";
import Grid from "../../../common/Grid/Grid";
import { screenWidthIsHigherThan } from "../../../hooks/useMediaQuery";
import clsx from "clsx";
import Link from "../../../common/Link/Link";
import labels from "../../../config/labels";

const ByLine = ({
  author,
  author_email,
  published_at,
  updated_at,
  town_admins_profile_page_path,
}) => {
  const classes = useStyles();

  const printAutorLabel = () => {
    if (author && author !== "") {
      return author;
    }
    if (author_email && author_email !== "") {
      return author_email;
    }
    return null;
  };

  const AuthorLabel = () => {
    if (town_admins_profile_page_path !== "") {
      return (
        <Link
          url={town_admins_profile_page_path}
          level="medium_13_px"
          color="grayForCredits"
          bold
          underlined
          children={labels.ONE_SPACE + labels.BY + printAutorLabel()}
          target="_blank"
          rel="noopener noreferrer"
        />
      );
    } else {
      return (
        <Typography level="medium_13_px" color="grayForCredits" bold>
          {labels.ONE_SPACE}
          {labels.BY}
          {printAutorLabel()}
        </Typography>
      );
    }
  };

  return (
    <>
      <Grid className={classes.container_by_line} container>
        <Grid item xs={12} sm={4}>
          {printAutorLabel() && <AuthorLabel />}
          {author_email && (
            <Link url={`mailto:${author_email}`}>
              <EmailIcon className={classes.mail_icon} fontSize="small" />
            </Link>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          className={clsx(
            screenWidthIsHigherThan(620)
              ? classes.byline_dates
              : classes.byline_dates_small
          )}
        >
          <div>
            <Typography level="t2_text_2" color="grayForCredits" bold>
              {labels.BYLINE_PUBLISHED}
            </Typography>
            <Typography
              level="t2_text_2"
              color="grayForCredits"
              className={classes.margin_left}
              bold
            >
              {published_at}
            </Typography>
          </div>
          {updated_at && (
            <div className={classes.min_width_breakpoint_sm}>
              <Typography level="t2_text_2" color="grayForCredits" bold>
                {labels.BYLINE_LAST_UPDATED}
              </Typography>
              <Typography
                level="t2_text_2"
                color="grayForCredits"
                className={classes.margin_left}
                bold
              >
                {updated_at}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

ByLine.propTypes = {
  author: PropTypes.string,
  author_email: PropTypes.string,
  published_at: PropTypes.string,
  updated_at: PropTypes.string,
  town_admins_profile_page_path: PropTypes.string,
};

export default ByLine;
