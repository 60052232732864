import { useEffect } from "react";
import withConfig from "../utils/withConfig";

const useAudioPlayer = () => {
  useEffect(() => {
    const AUDIO_PLAYER_SCRIPT = document.createElement("script");
    AUDIO_PLAYER_SCRIPT.setAttribute("fetchpriority", "high");
    AUDIO_PLAYER_SCRIPT.setAttribute("charset", "UTF-8");

    const SCRIPT_URL = new URL(`${withConfig("AUDIO_PLAYER_URL")}`);
    SCRIPT_URL.searchParams.set(
      "pageURL",
      `https://www.tapinto.net${window.location.pathname}`
    );
    SCRIPT_URL.searchParams.set(
      "textSelector",
      encodeURIComponent(btoa(".article_audio"))
    );
    SCRIPT_URL.searchParams.set("headlineSelector", "false");
    SCRIPT_URL.searchParams.set("articleSelector", "false");

    AUDIO_PLAYER_SCRIPT.src = SCRIPT_URL.toString();

    const audioPlayerContainer = document.querySelector(".trinity-tts-pb");

    audioPlayerContainer.parentNode.insertBefore(AUDIO_PLAYER_SCRIPT, audioPlayerContainer);
  }, []);
};

export default useAudioPlayer;